<template>
    <div style="max-width: 800px">
        <h2>Support Chat Message Analysis</h2>

        <p>
            Here you can analyse the support chats from you organization.

            This is done in 3 steps:
        </p>

        <v-stepper
            v-model="step"
            vertical
            class="elevation-0"
        >
            <v-stepper-step
                step="1"
                :complete="step > 1"
            >
                Provide the file for upload
            </v-stepper-step>

            <v-stepper-content step="1">
                <v-alert
                    color="blue-grey lighten-4"
                    dense
                    icon="fa-file-csv"
                    prominent
                >
                    Only .csv file types are supported at the moment.
                    First row of the .csv file must be a header row.
                </v-alert>
                <br >
                <v-form v-model="fileUploadValid">
                    <upload-file @selected-files="onSelectedFilesChange" required />
                </v-form>
                <v-btn
                    color="primary"
                    @click="uploadFile"
                    :disabled="!fileUploadValid"
                >
                    Upload File
                </v-btn>
            </v-stepper-content>

            <v-stepper-step
                step="2"
                :complete="step > 2"
            >
                Map columns
            </v-stepper-step>

            <v-stepper-content step="2">
                <v-card
                    color="grey lighten-3"
                    class="mb-12"
                    height="200px"
                >
                    <v-card-text v-if="!processingFile">
                        <p>
                            Map columns from source file.
                        </p>
                        <v-form v-model="formColumnMap">
                            <v-row>
                                <v-col cols=6>

                                    <v-select
                                        v-model="columnMap.timestamp"
                                        :items="mappingItems"
                                        item-text="label"
                                        item-value="key"
                                        outlined
                                        dense
                                        persistent-hint
                                        label="Timestamp Column"
                                        :rules="columnMapSelectorRules"
                                    />
                                </v-col>
                                <v-col cols=6>
                                    <v-select
                                        v-model="columnMap.content"
                                        :items="mappingItems"
                                        item-text="label"
                                        item-value="key"
                                        outlined
                                        dense
                                        persistent-hint
                                        label="Copntent Column"
                                        :rules="columnMapSelectorRules"
                                    />
                                </v-col>
                                <v-col cols=6>
                                    <v-select
                                        v-model="columnMap.author_id"
                                        :items="mappingItems"
                                        item-text="label"
                                        item-value="key"
                                        outlined
                                        dense
                                        persistent-hint
                                        label="Unique Author Identifier Column"
                                        :rules="columnMapSelectorRules"
                                    />
                                </v-col>
                                <v-col cols=6>
                                    <v-select
                                        v-model="columnMap.sender"
                                        :items="mappingItems"
                                        item-text="label"
                                        item-value="key"
                                        outlined
                                        dense
                                        persistent-hint
                                        label="Sender Column"
                                        :rules="columnMapSelectorRules"
                                    />
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-card-text>
                    <v-progress-circular v-if="processingFile"
                                         indeterminate
                                         color="primary"
                                         class="text-center"
                    />
                </v-card>
                <v-btn
                    color="primary"
                    @click="setColumnMap"
                    :disabled="!formColumnMap"
                >
                    Set column map
                </v-btn>
                <v-btn text @click="step = 1">
                    Cancel
                </v-btn>
            </v-stepper-content>

            <v-stepper-step step="3">
                See results
            </v-stepper-step>

            <v-stepper-content step="3">
                <v-btn
                    color="primary"
                    :to="{name: 'chat-analysis', params: {fileId: uploadedFileId}}"
                >
                    Navigate to results
                </v-btn>
            </v-stepper-content>
        </v-stepper>
    </div>
</template>

<script>
    import UploadFile from '@/components/upload-file.vue';
    import ChatAnalysisApi from '../webapi';

    export default {
        name: 'chat-analysis-pages-add',
        components: {
            UploadFile,
        },
        data: () => ({
            step: undefined,
            processingFile: false,

            fileUploadValid: false,
            selectedFiles: undefined,
            uploadedFileId: undefined,
            columnMap: {
                timestamp: undefined,
                message: undefined,
                sender: undefined,
            },
            mappingItems: [
                { key: 'datetime', label: 'Datetime' },
                { key: 'message', label: 'Message' },
                { key: 'sender', label: 'Sender' },
            ],
            formColumnMap: false,
            columnMapSelectorRules: [
                (v) => !!v || 'Column needs to be set',
                (v) => !!v || 'Column needs to be set.',
            ],
        }),
        methods: {
            uploadFile() {
                this.$helpers.notify('uploading');
                ChatAnalysisApi.uploadFile(this.selectedFiles).then((response) => {
                    if (response.success === true) {
                        this.$helpers.notifySuccess('File successful');
                        this.mappingItems = response.columns.map((col) => ({ key: col, label: col }));
                        this.uploadedFileId = response.file_id;
                        this.step = 2;
                    } else {
                        this.$helpers.notifyError('File upload failed');
                    }
                });
            },
            setColumnMap() {
                // Sets the column map.
                this.processingFile = true;
                ChatAnalysisApi.setColumnMap(this.uploadedFileId, this.columnMap).then(() => {
                    this.$helpers.notifySuccess('Columns map set.');
                    this.processingFile = false;
                    this.step = 3;
                }).catch((err) => {
                    this.$helpers.notifyError(err);
                });
            },
            onSelectedFilesChange(files) {
                this.selectedFiles = files;
            },
        },
    };
</script>
