<template>
    <v-file-input
        v-model="files"
        color="primary"
        counter
        label="Select your file"
        prepend-icon="mdi-paperclip"
        outlined
        :rules="rules"
        :show-size="1000"
    >
        <template v-slot:selection="{ index, text }">
            <v-chip
                v-if="index < 2"
                color="deep-purple accent-4"
                dark
                label
                small
            >
                {{ text }}
            </v-chip>

            <span
                v-else-if="index === 2"
                class="overline grey--text text--darken-3 mx-2"
            >
                +{{ files.length - 2 }} File(s)
            </span>
        </template>
    </v-file-input>
</template>

<script>
    export default {
        name: 'file-upload',
        props: {
            required: {
                type: Boolean,
                default: false,
            },
        },
        data: () => ({
            files: [],
        }),
        computed: {
            rules() {
                const rules = [];

                if (this.required === true) {
                    rules.push((v) => (!!v && !!v.name) || 'File is required');
                }
                return rules;
            },
        },
        watch: {
            files(newValue) {
                this.$emit('selected-files', newValue);
            },
        },
    };
</script>
